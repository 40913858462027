<template>
    <div class="footer">
        <div class="flex-center pact">
            <div class="item" style="margin-right:0" @click="linkTo('https://www.yqdf.xyz/agreements/yinsi.html')">隐私协议
            </div>
            <div class="line"></div>
            <div class="item" style="margin-right:0" @click="linkTo('https://www.yqdf.xyz/agreements/yonghufuwu.html')">用户协议
            </div>
            <div class="line"></div>
            <div class="item" @click="linkTo('https://www.yqdf.xyz/agreements/Permission_request.html')">应用权限</div>
        </div>
        <div class="flex-center">
            <div class="item">Copyright ©2023武汉一起兜风科技有限公司版权所有</div>
        </div>
        <div class="flex-center">
            <div class="item wangan"
                @click="linkTo('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006355')"> <img
                    src="@/assets/common/guohui.png" alt="">鄂公网安备 42018502006355号</div>
            <div class="item" @click="linkTo('https://beian.miit.gov.cn/#/Integrated/index')">鄂ICP备2022012234号-2</div>
            <div class="item" @click="linkTo('https://jbts.mct.gov.cn')">12318全国文化市场举报</div>
            <div class="item" @click="linkTo('http://jubao.py.cnhubei.com/')">湖北省网上有害信息举报专区</div>
        </div>
        <div class="flex-center">
            <div class="item" @click="linkTo('https://www.shdf.gov.cn/shdf/channels/740.html')">中国扫黄打非-网上举报</div>
            <div class="item" @click="linkTo('https://www.12377.cn/jbxzxq/zpljbxzxq.html?spm=zm1033-001.0.0.1.SOXBdI')">
                电信诈骗举报专区</div>
            <div class="item"
                @click="linkTo('https://www.12377.cn/jbxzxq/64d38691937611ebb858c9da04cf59d3_web.html?spm=zm1033-001.0.0.1.SOXBdI&smallHarmType=64d38691937611ebb858c9da04cf59d3')">
                涉历史虚无主义举报专区</div>
            <div class="item">不良信息举报邮箱 kf@yqdf.xyz</div>
        </div>
        <div class="flex-center">
            <div class="item">应用名称：《一起兜风》</div>
            <div class="item">开发者：武汉一起兜风科技有限公司</div>
            <div class="item">版本号：1.0.7</div>
        </div>
        <div class="flex-center">
            <div class="item">公司地址：武汉东湖新技术开发区关东街道光谷大道58号关南福星医药园7栋 </div>
            <div class="item">客服电话：027-67120868</div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        linkTo(url) {
            window.open(url, '_blank')
        }
    },
    created() { },
    mounted() { }
}
</script>

<style scoped lang=scss>
.footer {
    width: 100vw;
    // height: 600px;
    padding-top: 100px;
    padding-bottom: 64px;
    background: rgb(43, 47, 62);
}

.flex-center {
    margin-bottom: 12px;

    .item {
        margin-right: 30px;
        font-size: 16px;
        color: rgba(204, 204, 204, 1);
        cursor: pointer;
    }

    .item:last-child {
        margin-right: 0;
    }
}

.pact {
    align-items: center;

    .item {
        // color: rgb(255, 212, 0);
        color: rgba(204, 204, 204, 1);
        padding: 0 20px;
    }
}

.line {
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.4);
    margin-top: 4px;
}

.wangan {
    height: 21px;
    line-height: 21px;

    img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 5px;
    }

    // vertical-align: middle;
}
</style>