<template>
  <div class="home">
    <div class="section-one">
      <img class="banner" src="../../assets/home/banner@2x.png" alt="">
      <img class="banner-text" src="../../assets/home/banner-text2.png" alt="">
      <div class="download">
        <div class="btn " style="margin-right: 73px;">
          <img class="ios" src="../../assets/home/ios.png" alt=""> App Store 下载
          <img class="qr" src="../../assets/home/yqdf_ios.png" alt="">
          <!-- <img class="qr" src="../../assets/home/logo.png" alt=""> -->
        </div>
        <div class="btn2" style="margin-right: 73px;" @click="downloadApk">
          <img class="android" src="../../assets/home/android.png" alt=""> Andriod 下载
          <img class="qr" src="../../assets/home/yqdf_android-new.png" alt="">
          <!-- <img class="qr" src="../../assets/home/logo.png" alt=""> -->
        </div>
        <div class="btn3">
          <img class="android" src="../../assets/home/windows.png" alt=""> PC 下载
          <p @click="downloadWindowsExe">windows下载</p>
          <p @click="downloadSimulatorApk">模拟器下载</p>
          <!-- <img class="qr" src="../../assets/home/yqdf_android-new.png" alt=""> -->
          <!-- <img class="qr" src="../../assets/home/logo.png" alt=""> -->
        </div>
      </div>
    </div>

    <div class="section-two flex-center">
      <img src="../../assets/home/img01@2x.png" alt="">
      <div class="desc">
        <div class="title">酷炫玩法</div>
        <p>致力于打造沉浸式社交体验，一切感觉就在身边</p>
      </div>
    </div>

    <div class="section-three flex-center">
      <div class="desc">
        <div class="title">优质好声音</div>
        <p>宝藏歌手，男神女神，邂逅心动好声音</p>
      </div>
      <img src="../../assets/home/img02@2x.png" alt="">
    </div>

    <div class="section-four flex-center">
      <img src="../../assets/home/img03@2x.png" alt="">
      <div class="desc">
        <div class="title">高端社交圈</div>
        <p>所有用户都需要严格审核，没有套路，只有真诚</p>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from 'components/Footer'
export default {
  components: {
    Footer
  },
  data() {
    return {}
  },
  methods: {
    downloadApk() {
      window.location.href = 'https://download.yqdf.xyz/install%2Fandroid%2Fyqdf_release_guanwang.apk?t=' + Date.now();
    },
    downloadSimulatorApk() {
      window.location.href = 'https://download.yqdf.xyz/install/simulator/yqdf_simulator.apk?t=' + Date.now();
    },
    downloadWindowsExe() {
      window.location.href = 'https://appdownload.tos-cn-shanghai.volces.com/install%2Fwindows%2Fdoufeng1.0.exe?t=' + Date.now();
    }
  },
  created() { },
  mounted() { }
}

</script>

<style scoped lang=scss>
.home {
  width: 100vw;
}

.title {
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 60px;
  color: rgb(51, 51, 51)
}

.desc {
  font-size: 26px;
}

.section-one {
  width: 100%;
  position: relative;
  z-index: 1;

  .banner {
    width: 100%;
  }
}

.section-two {
  width: 100%;
  padding-top: 280px;
  padding-bottom: 280px;
  align-items: center;

  img {
    width: 591px;
    height: 483px;
    margin-right: 230px;
  }
}

.section-three {
  width: 100%;
  // height: 600px;
  padding-top: 280px;
  padding-bottom: 280px;
  align-items: center;
  background: rgb(244, 245, 250);

  img {
    width: 591px;
    height: 483px;
    margin-left: 230px;
  }
}

.section-four {
  width: 100%;
  padding-top: 280px;
  padding-bottom: 280px;
  align-items: center;

  img {
    width: 591px;
    height: 483px;
    margin-right: 230px;
  }
}

.banner-text {
  width: 1000px;
  /* height: 502px; */
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 199px;
}

.download {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 649px;
  width: 100%;
  justify-content: center;

}

.btn:hover {
  height: 380px;
  transition: .3s;
}

.btn3:hover {
  height: 300px;
  transition: .3s;

  p:hover {
    color: rgb(255, 212, 0);
  }
}

.btn {
  width: 340px;
  height: 96px;
  opacity: 1;
  cursor: pointer;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);

  border: 2px solid rgba(255, 255, 255, 1);

  backdrop-filter: blur(20px);
  text-align: center;
  line-height: 96px;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  transition: .3s;
  overflow: hidden;
  // height: 380px;

  img {
    margin-right: 15px;
  }

}

.btn2 {
  width: 340px;
  height: 96px;
  opacity: 1;
  cursor: pointer;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);

  border: 2px solid rgba(255, 255, 255, 1);

  backdrop-filter: blur(20px);
  text-align: center;
  line-height: 96px;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  transition: .3s;
  overflow: hidden;
  // height: 380px;

  img {
    margin-right: 15px;
  }

}

.btn3 {
  width: 340px;
  height: 96px;
  opacity: 1;
  cursor: pointer;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);

  border: 2px solid rgba(255, 255, 255, 1);

  backdrop-filter: blur(20px);
  text-align: center;
  line-height: 96px;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  transition: .3s;
  overflow: hidden;
  // height: 380px;

  img {
    margin-right: 15px;
  }

}

.ios {
  width: 36px;
  height: 44px;
  vertical-align: middle;
  margin-top: -10px;
}

.android {
  width: 38px;
  height: 42px;
  vertical-align: middle;
  margin-top: -10px;
}

.qr {
  width: 240px;
  height: 240px;
  background: #000;
  margin-right: 0 !important;
  border-radius: 14px;
}
</style>