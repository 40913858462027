<template>
    <div class="nav flex-center">
        <div class="container flex-between">
            <div class="logo flex">
                <img src="../../assets/home/logo.png" alt="">
                <p>一起兜风</p>
            </div>
            <div class="tab flex-between">
                <div class="tabItem" :class="currentPath == item.path ? 'tabSelected' : ''" v-for="(item, index) in tabList"
                    :key="index" @click="navTo(item.path)">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabList: [
                { name: '首页', path: '/' },
                { name: '关于我们', path: '/aboutUs' },
                { name: '加入我们', path: '/joinUs' },
            ],
            currentPath: ''
        }
    },
    methods: {
        navTo(path) {
            this.$router.push({
                path: path
            })
        }
    },
    created() { },
    mounted() { },
    watch: {
        "$route": {
            handler(val) {
                console.log(val)
                this.currentPath = val.path
            },
            immediate: true
        },
    }
}
</script>

<style scoped lang="scss">
.nav {
    width: 100%;
    height: 90px;
    position: fixed;
    z-index: 3;
    background: rgba(0, 0, 0, 0.6);

    .container {
        width: 70%;
        max-width: 1920px;
        height: 60px;
        padding: 15px 0;
        // background: yellow;
        align-items: center;
    }
}

.logo {
    // width: 100px;
    height: 48px;
    // background: greenyellow;
    align-items: center;

    img {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        border-radius: 10px;
    }

    p {
        font-size: 18px;
        font-weight: 600;
        color: rgb(255, 212, 0)
    }

    ;
}

.tabItem {
    position: relative;
    cursor: pointer;
    margin: 0 30px;
    font-size: 18px;
    color: rgba(183, 187, 195);
    mix-blend-mode: difference;
}

.tabSelected {
    color: white !important;

}

.tabSelected::after {
    content: '';
    width: 20px;
    height: 4px;
    background: rgb(255, 212, 0);
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 20px;
}</style>